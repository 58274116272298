import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { logolight } from "../../constants/imports";
import { IoMdMenu } from "react-icons/io";
import MobileNav from "../generic/MobileNav";
import { IoNotifications } from "react-icons/io5";
import { IoIosSettings } from "react-icons/io";
import { IoLogOutSharp } from "react-icons/io5";

const Navbar = () => {
  var authuser = useSelector((state) => state.authuser);
  var dispatcher = useDispatch();
  var userschool = useSelector((state) => state.userschool);
  var navigate = useNavigate();
  const [showmobilenav, setshowmobilenav] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 644px)",
  });
  const logout = () => {
    localStorage.clear();
    dispatcher({ type: "set-auth-user", payload: {} });
    navigate("/login");
  };

  return (
    <div
      className={`p-4 h-[10vh]   flex ${
        isDesktopOrLaptop ? "justify-end" : "justify-between"
      }  bg-white w-full items-center  shadow z-40`}
    >
      {isDesktopOrLaptop ? (
        <Menu as="div" className="  self-center">
          <div>
            <Menu.Button className=" p-1 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
              <div className="bg-faint p-2 self-center rounded-xl flex-center items-center">
                <p className="text-[12px] inline font-light mr-2 ">
                  {" "}
                  Hi,{authuser.firstname}
                </p>
                <img
                  className="rounded-[25px]   inline"
                  height="30px"
                  width="30px"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3URjWpcZfPfzAHxrU_Xms2GzfUJmvWXGjuw&s"
                />
              </div>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 py-4 z-[135454545] focus:outline-none">
              <Menu.Item>
                <div className="p-3 bg-customBlack flex rounded-lg ">
                  <div>
                    <img src="/images/avatar.png" alt="" />
                  </div>
                  <div className="   text-gray-300">
                    {" "}
                    <p className="text-sm my-2 font-light ">
                      {authuser && authuser.firstname}
                    </p>
                    <p className="text-xs font-light mr-2 truncate    ">
                      {authuser && authuser.email}
                    </p>
                    <p className="text-xs mr-2 text-ellipsis overflow-hidden  font-light  ">
                      {authuser && authuser.phone}
                    </p>
                  </div>
                </div>
              </Menu.Item>

              {authuser.school != "nill" && !userschool.locked && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className="active:bg-gray-200 rounded-sm my-2  text-sm block px-4 text-gray-700 cursor-pointer focus:bg-gray-200"
                      onClick={() => {
                        navigate("/dashboard/settings");
                      }}   >
                      <IoIosSettings className=" text-[#005A82] inline" />{" "}
                      Settings
                    </button>
                  )}
                </Menu.Item>
              )}
              <hr />
              <Menu.Item>
                {({ active }) => (
                  <button
                    className="active:bg-gray-200 block  text-sm rounded-sm px-4  text-gray-700 cursor-pointer focus:bg-gray-200"
                    onClick={logout}
                  >
                    <IoLogOutSharp className=" text-[#005A82] inline" /> Sign
                    out
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      ) : (
        <img width={100} className="self-start" src={logolight} />
      )}

      <div className="flex justify-center items-center">
        <IoNotifications
          className="text-[#005A82] active:shadow-lg shadow"
          onClick={() => {
            navigate("/dashboard/notifications");
          }}
          size={30}
        />
        {!isDesktopOrLaptop && (
          <div className="flex justify-center items-center">
            <Menu as="div" className="  self-center">
              <div>
                <Menu.Button className=" p-1 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                  <div className="bg-faint p-2 self-center rounded-xl flex-center items-center">
                    <img
                      className="rounded-[25px] inline"
                      height="30px"
                      width="30px"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3URjWpcZfPfzAHxrU_Xms2GzfUJmvWXGjuw&s"
                    />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 py-4 z-[135454545454] focus:outline-none">
                  <Menu.Item>
                    <div className="p-3 bg-customBlack flex rounded-lg ">
                      <div>
                        <img src="/images/avatar.png" alt="" />
                      </div>
                      <div className="   text-gray-300">
                        {" "}
                        <p className="text-sm my-2 font-light ">
                          {authuser && authuser.firstname}
                        </p>
                        <p className="text-xs font-light mr-2 truncate    ">
                          {authuser && authuser.email}
                        </p>
                        <p className="text-xs mr-2 text-ellipsis overflow-hidden  font-light  ">
                          {authuser && authuser.phone}
                        </p>
                      </div>
                    </div>
                  </Menu.Item>

                  {authuser.school != "nill" && !userschool.locked && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className="active:bg-gray-200 rounded-sm my-2  text-sm block px-4 text-gray-700 cursor-pointer focus:bg-gray-200"
                          onClick={() => {
                            navigate("/dashboard/settings");
                          }}
                        >
                          <IoIosSettings className=" text-[#005A82] inline" />{" "}
                          Settings
                        </button>
                      )}
                    </Menu.Item>
                  )}
                  <hr />
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className="active:bg-gray-200 block  text-sm rounded-sm px-4  text-gray-700 cursor-pointer focus:bg-gray-200"
                        onClick={logout}
                      >
                        <IoLogOutSharp className=" text-[#005A82] inline" />{" "}
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>

            <IoMdMenu
              onClick={() => {
                setshowmobilenav((prev) => !prev);
              }}
              className="text-[#1E598C]"
              size={30}
            />
          </div>
        )}
        {showmobilenav && <MobileNav show={setshowmobilenav} />}{" "}
      </div>
    </div>
  );
};

export { Navbar };
