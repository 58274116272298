// import React from "react";
// import { Controller } from "react-hook-form";

 

// const InputField = ({
//   control,
//   name,
//   def_value,
//   rules = {},
//   placeholder,
//   title,
//   type,
//   isDisabled,
//   maxLength,
//   showCount,
//   isTextArea,
// }) => {
//   return (
//     <Controller
//       control={control}
//       name={name}
//       defaultValue={def_value}
//       rules={rules}
//       render={({ field: { value, onChange }, fieldState: { error } }) => (
//         <div className="my-4">
//           {title && (
//             <div className="flex items-center justify-between">
//               <p className="text-xs mb-2">{title}</p>
//               <div>
//                 {showCount && (
//                   <p className="text-xs">{`${
//                     value?.length > 0 ? value?.length : 0
//                   }${maxLength ? "/" + maxLength : ""}`}</p>
//                 )}
//               </div>
//             </div>
//           )}
//           {isTextArea ? (
//             <textarea
//               value={value}
//               placeholder={placeholder}
//               onChange={onChange}
//               className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border ${
//                 error ? "border-red-500" : "border-gray-200"
//               }`}
//               maxLength={maxLength || undefined}
//               rows={3}
//             ></textarea>
//           ) : (
//             <input
//             disabled={isDisabled}
            
//               type={type || "text"}
//               value={value}
//               placeholder={placeholder}
//               onChange={onChange}
//               className={`block bg-gray-50 text-xs py-3 px-4  active:border-primary  rounded-sm w-full shadow-xs border ${
//                 error ? "border-red-500" : "border-gray-200"
//               }`}
//               maxLength={maxLength || undefined}
//             />
//           )}
//           {error && (
//             <span className="text-[#ff0000]  text-xs self-stretch">
//               {error.message || "Error"}
//             </span>
//           )}
//         </div>
//       )}
//     />
//   );
// };

// export default InputField;


import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { IoMdClose, IoMdEye, IoMdEyeOff } from "react-icons/io";

 

const InputField = ({
  control,
  name,
  def_value,
  rules = {},
  placeholder,
  title,
  type,
  isDisabled,
  maxLength,
  showCount,
  isTextArea,
}) => {

  const [inptype, settype] = useState(type|| "text");
  const [passvisible, setpassvisible] = useState(false);
  return (
    <Controller
      control={control}
      defaultValue={def_value}
      name={name}
      rules={rules}
      render={({ field: { value=def_value, onChange }, fieldState: { error } }) => (
        <div className="my-4  ">
          {title && (
            <div className="flex items-center justify-between">
              <p className="text-xs mb-2">{title}</p>
              <div>
                {showCount && (
                  <p className="text-xs">{`${
                    value?.length > 0 ? value?.length : 0
                  }${maxLength ? "/" + maxLength : ""}`}</p>
                )}
              </div>
            </div>
          )}
          {isTextArea ? (
            <textarea
              value={value}
              placeholder={placeholder}
              onChange={(text) => onChange(text)}
              className={`block bg-gray-50 text-xs py-3 px-4 rounded-sm w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              maxLength={maxLength || undefined}
              rows={3}
            ></textarea>
          ) : (<div 
            className={`  flex bg-gray-50 text-xs justify-center items-center active:border-primary  rounded-md w-full shadow-xs border ${
              error ? "border-red-500" : "border-gray-200"
            }`}          
          >
            <input
            disabled={isDisabled}
            
              type={inptype}
             
              placeholder={def_value}
              onChange={(text) => onChange(text)}
              className={`block bg-gray-50 text-xs py-3 px-4  active:border-primary  rounded-md w-full shadow-xs border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              maxLength={maxLength || undefined}
            />

            {type == "password" &&
              (passvisible ? (
                <IoMdEye
                  onClick={() => {
                    settype("text");
                    if (inptype == "password") {
                      settype("text");
                    } else {
                      settype("password");
                    }
                    setpassvisible(!passvisible);
                  }}
                  size={20}
                  className=" text-textbg inline m-1 "
                />
              ) : (
                <IoMdEyeOff
                  onClick={() => {
                    settype("text");
                    if (inptype == "password") {
                      settype("text");
                    } else {
                      settype("password");
                    }
                    setpassvisible(!passvisible);
                  }}
                  size={20}
                  className=" text-textbg inline m-1 "
                />
              ))}
        </div>  )}
          {error && (
            <span className="text-[#ff0000]  text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
