import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReviewItem } from "../../components/Review/ReviewItem";
import { SchoolRating } from "../../components/Review/SchoolRating";
import Spinner from "../../components/generic/Spinner";
import { onGetSchoolReview } from "../../services/review";
import { omited_reviewitem } from "../../constants/data";
const Review = () => {
  const school_ = useSelector((state) => state.userschool);
  const [loading, setLoading] = useState(false);
  const [rating, setrating] = useState(0);
  const [reviewItems, setReviewItems] = useState([]);
  const HeaderItems = ["Review item title", "Score", "Status", "Last updated"];
  const getSelectedschoolReview = async () => {
    setLoading(true);
    var formdata = { schoolname: school_.schoolname };
    var res = await onGetSchoolReview(formdata);
    setLoading(false);

    setReviewItems(res.review_items.concat(omited_reviewitem));
  };

  useEffect(() => {
    getSelectedschoolReview();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (school_.schoolname == undefined) {
      navigate("/");
      return;
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    let percent = 0;
    reviewItems.map((k) => {
      sum = sum + k.score;
    });

    percent = Math.ceil((sum / 100) * 100);
    setrating(percent);
  }, [reviewItems]);


  const YearItem =({title, isActive})=>{


    return (<div className={`p-2 rounded-md cursor-pointer font-[500] ${isActive?"bg-black cursor-pointer text-white":"text-gray-400 border border-gray-300"} font-[500]`}>
      {title}
    </div>)
  }
  return (
    <div className="">
      <Spinner loading={loading} />
      <div className="flex gap-3 m-3">
      <YearItem isActive={true} title={"2024"}/>
      <YearItem isActive={false} title={"2025"}/>
      </div>

      
      <div className="p-4 bg-white shadow  ">
        <p className="text-primary text-[20px] font-semibold">
     2024/2025     Review & Rating for {school_.schoolname}
        </p>

        <small className="font-light">
          View review details of your school by Gov. Staff
        </small>
      </div>

      <div className="bg-customGray flex flex-col p-4 m-4">
        <div className=" p-3  bg-white">
          <SchoolRating percent={rating} num={Math.ceil((rating / 100) * 5)} />

          <div className="p-4 m-2 flex justify-between  bg-[#999999]">
            {HeaderItems.map((item, key) => (
              <p
                className={`font-bold ${
                  key == 0 ? "w-[60%]" : ""
                } capitalize  text-white`}
              >
                {item}
              </p>
            ))}
          </div>

          {reviewItems.map((item, key) => (
            <ReviewItem key_={key} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
export { Review };
